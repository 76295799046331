<template >
  <div>
    <Imprensa />
  </div>
</template>

<script>
import Imprensa from "../components/imprensa/imprensa.vue";
export default {
  components: {
    Imprensa,
  },
};
</script>
<style scoped>
</style>