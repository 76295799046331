<template>
  <div>
    <TopMenu />
    <div style="margin-top: 20vh">
      <div class="container mt-5 tracking-in-contract-bck text-center">
        <h1 style="color: #f54308">Imprensa</h1>
      </div>
      <div class="col text-center">
        <div class="col">
          <video autoplay controls src="@/assets/mg-cobranca.mp4" width="90%" height="auto"></video>
         </div>      
      </div>
    </div>
    <!-- Tópicos -->

  </div>
</template>

<script>
import TopMenu from "../menu/topMenu.vue";
export default {
  name: "Imprensa",
  data() {
    return {};
  },
  components: {
    TopMenu,
  },
};
</script>


<style scoped>
h1,h4{
  text-transform: uppercase;
}
.tracking-in-contract-bck {
  -webkit-animation: tracking-in-contract-bck 3s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck 3s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}
.div-row {
  display: flex;
  flex-direction: row;
}

@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.iframe{
    width:1000px;
    height:550px;
  }

@media(max-width: 860px){
  .iframe{
    width:600px;
    height:350px;
  }
}

@media(max-width: 600px){
  .iframe{
    width:400px;
    height:250px;
  }
}

@media(max-width: 450px){
  .iframe{
    width:350px;
    height:150px;
  }
}
</style>